import React from 'react';
import './Header.css';

const Header = () => {
  return (
    <header className="header">
      <img src={process.env.PUBLIC_URL + '/The Maze Icon.png'} alt="Maze Icon" className="maze-icon" />
      <h1>The Lost Maze</h1>
      <div className="support-section">
        <img src={process.env.PUBLIC_URL + '/Heart.png'} alt="Heart" className="heart-icon" />
        <a 
            href="https://www.patreon.com/user?u=50646118" 
            target="_blank" 
            className="support-link"
            rel="noopener noreferrer"
        >  Support my work
        </a>
      </div>
      <h2 data-nosnippet className="fantasy">FANTASY</h2>
    </header>
  );
};

export default Header;
