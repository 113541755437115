import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function Sell() {
  const navigate = useNavigate();
  const [userInput, setUserInput] = useState('');

  const handleInputChange = (event) => {
    const input = event.target.value;
    setUserInput(input);
    if (input.toUpperCase() === 'NEBRASKA') {
      navigate('/merge'); // Navigate when the condition is met
    }
  };

  return (
    <div className='App'>
        <h1>Look Much Closer</h1>
        <img src="/wonderful.png" alt="Display" className="Center-Image"/>
      <input
        type="text"
        value={userInput}
        onChange={handleInputChange}
        className="Center-Input"
        placeholder=""
      />
      {/* Download button */}
      
    </div>
  );
}

export default Sell;
