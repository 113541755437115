import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';


function Gold() {
  const navigate = useNavigate();
  const [userInput, setUserInput] = useState('');

  const handleInputChange = (event) => {
    const input = event.target.value;
    setUserInput(input);
    if (input.toUpperCase() === 'YOU ARE NOT ALONE') {
      navigate('/silver'); // Navigate to Air component when 'ESCAPE' is entered
    }
  };

  return <div className='App'>
    <h1>To get out, you must find the source</h1>
    <input
  type="text"
  value={userInput}
  onChange={handleInputChange}
  className="Center-Input"
  placeholder=""
/>
  </div>
  
  
}

export default Gold;
