import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function Sell() {
  const navigate = useNavigate();
  const [userInput, setUserInput] = useState('');

  const handleInputChange = (event) => {
    const input = event.target.value;
    setUserInput(input);
    if (input.toUpperCase() === '2019') {
      navigate('/freeornot');
    }
  };

  return (
    <div className='App'>
      <h1>The Maze will set you free <img src={process.env.PUBLIC_URL + '/Magnifier.png'} alt="Magnifier" style={{ width: '16px', height: '16px', verticalAlign: 'middle' }} /></h1>
      <input
        type="text"
        value={userInput}
        onChange={handleInputChange}
        className="Center-Input"
        placeholder="Enter the key..."
      />
    </div>
  );
}

export default Sell;
