import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';


function Air() {
  const navigate = useNavigate();
  const [userInput, setUserInput] = useState('');

  const handleInputChange = (event) => {
    const input = event.target.value;
    setUserInput(input);
    if (input.toUpperCase() === 'DOOR') {
      navigate('/gold');
    }
  };

  return <div className='App'>
    <h1>I am a four-letter word</h1>
    <h1>People walk through me</h1>
    <h1>Without touching me</h1>
    <h1>Not so long ago I didn't exist</h1>
    <h1>What am I?</h1>
    <input
  type="text"
  value={userInput}
  onChange={handleInputChange}
  className="Center-Input"
  placeholder=""
/>
  </div>
  
  
}

export default Air;
