import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Crawl.css";

const MazeGame = () => {
  const navigate = useNavigate();
  const mazeSize = 10;
  const exitPosition = { x: mazeSize - 1, y: mazeSize - 1 };

  const [playerPosition, setPlayerPosition] = useState({ x: 0, y: 0 });
  const [enemies, setEnemies] = useState([
    { x: 4, y: 4 },
    { x: 2, y: 2 },
    { x: 3, y: 3 },
    { x: 8, y: 8 },
    { x: 3, y: 3 },
    { x: 8, y: 8 },
    { x: 3, y: 3 },
    { x: 8, y: 8 },
    { x: 8, y: 8 },
    { x: 3, y: 3 },
    { x: 8, y: 8 },
  ]);

  const movePlayer = (direction) => {
    setPlayerPosition((prevPosition) => {
      let newX = prevPosition.x;
      let newY = prevPosition.y;

      switch (direction) {
        case "up":
          newY = newY > 0 ? newY - 1 : newY;
          break;
        case "down":
          newY = newY < mazeSize - 1 ? newY + 1 : newY;
          break;
        case "left":
          newX = newX > 0 ? newX - 1 : newX;
          break;
        case "right":
          newX = newX < mazeSize - 1 ? newX + 1 : newX;
          break;
        default:
          break;
      }

      if (newX === exitPosition.x && newY === exitPosition.y) {
        navigate("/walk");
      }

      return { x: newX, y: newY };
    });
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      switch (event.key) {
        case "ArrowUp":
          movePlayer("up");
          break;
        case "ArrowDown":
          movePlayer("down");
          break;
        case "ArrowLeft":
          movePlayer("left");
          break;
        case "ArrowRight":
          movePlayer("right");
          break;
        default:
          break;
      }
    };

    window.addEventListener("keydown", handleKeyPress);
    return () => window.removeEventListener("keydown", handleKeyPress);
  },);

  useEffect(() => {
    const moveEnemies = () => {
      setEnemies((currentEnemies) =>
        currentEnemies.map((enemy) => {
          let newX = Math.max(0, Math.min(mazeSize - 1, enemy.x + (Math.random() < 0.5 ? -1 : 1)));
          let newY = Math.max(0, Math.min(mazeSize - 1, enemy.y + (Math.random() < 0.5 ? -1 : 1)));
          return { x: newX, y: newY };
        })
      );
    };

    const enemyMoveInterval = setInterval(moveEnemies, 1000);
    return () => clearInterval(enemyMoveInterval);
  }, []);

  useEffect(() => {
    const checkForCollision = () => {
      if (enemies.some((enemy) => enemy.x === playerPosition.x && enemy.y === playerPosition.y)) {
        setPlayerPosition({ x: 0, y: 0 });
      }
    };

    checkForCollision();
  }, [playerPosition, enemies]);

  return (
    <div className="maze-game">
      <div className="maze">
        {Array.from({ length: mazeSize * mazeSize }, (_, index) => {
          const rowIndex = Math.floor(index / mazeSize);
          const columnIndex = index % mazeSize;
          const isPlayer = playerPosition.x === columnIndex && playerPosition.y === rowIndex;
          const isExit = exitPosition.x === columnIndex && exitPosition.y === rowIndex;
          const isEnemy = enemies.some((enemy) => enemy.x === columnIndex && enemy.y === rowIndex);

          return (
            <div key={index} className={`maze-cell ${isPlayer ? "character" : ""} ${isExit ? "exit" : ""}`}>
              {isPlayer && <span role="img" aria-label="player">🧍</span>}
              {isExit && <span role="img" aria-label="exit">🚪</span>}
              {isEnemy && <img src="/minotaur.png" alt="Enemy" className="enemy" />}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MazeGame;
