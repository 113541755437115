import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function Debt() {
  const navigate = useNavigate();
  const [userInput, setUserInput] = useState('');

  const handleInputChange = (event) => {
    const input = event.target.value;
    setUserInput(input);
    if (input.toUpperCase() === 'CIRCLES') {
      navigate('/buy'); // Navigate when the condition is met
    }
  };

  return (
    <div className='App'>
      <h1>Listen and find</h1>
      <a href="/listen.mp4" download className="Download-Button">
        Download
      </a>
      <input
        type="text"
        value={userInput}
        onChange={handleInputChange}
        className="Center-Input"
        placeholder=""
      />
      {/* Download button */}
      
    </div>
  );
}

export default Debt;
