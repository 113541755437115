import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function Iron() {
  const navigate = useNavigate();
  const [userInput, setUserInput] = useState('');

  const handleInputChange = (event) => {
    const input = event.target.value;
    setUserInput(input);
    if (input.toUpperCase() === 'ESCAPE') {
      navigate('/air'); // Navigate to Air component when 'ESCAPE' is entered
    }
  };

  return (
    <div className="App">
    <div className="Iron">
    <h1>You don't see what you don't believe in</h1>
      <img src="/Untitled-1.jpg" alt="Display" className="Center-Image"/>
      
      <input
        type="text"
        value={userInput}
        onChange={handleInputChange}
        className="Center-Input"
        placeholder=""
      />
    </div>
    </div>
  );
}

export default Iron;
