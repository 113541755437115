import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

function Juliet() {
  const navigate = useNavigate();
  const [userInput, setUserInput] = useState('');
  const videoRef = useRef(null); // Create a ref for the video element

  const handleInputChange = (event) => {
    const input = event.target.value;
    setUserInput(input);
    if (input.toUpperCase() === 'BELL BIRD') {
      navigate('/out'); // Navigate when the condition is met
    }
  };

  return (
    <div className='App'>
        <h1>A scream in the sky, but what is it ?</h1>
        <audio className="videoplayer" ref={videoRef} controls>
          <source src="lleb.mp4" type="audio/mpeg" /> {/* Change to the correct file extension and MIME type if needed */}
          Your browser does not support the audio element.
        </audio>
      <input
        type="text"
        value={userInput}
        onChange={handleInputChange}
        className="Center-Input"
        placeholder=""
      />
    </div>
  );
}

export default Juliet;
