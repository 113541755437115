// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
    color: rgb(45, 45, 45);
    background-color: rgb(202, 202, 202);
    padding: 2px 20px;
    display: flex; 
    align-items: center;
    justify-content: flex-start;
    font-size: 0.8em;
}

.header h1 {
    margin: 10px 10px 10px 10px; 
    font-family: 'Rubik Maze', system-ui;
}

.fantasy {
    margin-left: auto;
    margin-right: 10px;
    color: rgb(202, 202, 202);
    font-family: arial;
    font-size: 0.8em;
}

.maze-icon {
    width: 40px;
    height: auto;
}

.maze-icon:hover {
    cursor:help
}

.support-section {
margin-left: 5%;
}

.heart-icon{
    width: 20px;
    height: auto;
}

.support-link {
    color: #000; /* Adjust color as needed */
    text-decoration: none;
    margin-left:10px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
  }
  
  .support-link:hover {
    text-decoration: underline;
  }`, "",{"version":3,"sources":["webpack://./src/Header.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,oCAAoC;IACpC,iBAAiB;IACjB,aAAa;IACb,mBAAmB;IACnB,2BAA2B;IAC3B,gBAAgB;AACpB;;AAEA;IACI,2BAA2B;IAC3B,oCAAoC;AACxC;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;IAClB,yBAAyB;IACzB,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI;AACJ;;AAEA;AACA,eAAe;AACf;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,WAAW,EAAE,2BAA2B;IACxC,qBAAqB;IACrB,gBAAgB;IAChB,gDAAgD;EAClD;;EAEA;IACE,0BAA0B;EAC5B","sourcesContent":[".header {\n    color: rgb(45, 45, 45);\n    background-color: rgb(202, 202, 202);\n    padding: 2px 20px;\n    display: flex; \n    align-items: center;\n    justify-content: flex-start;\n    font-size: 0.8em;\n}\n\n.header h1 {\n    margin: 10px 10px 10px 10px; \n    font-family: 'Rubik Maze', system-ui;\n}\n\n.fantasy {\n    margin-left: auto;\n    margin-right: 10px;\n    color: rgb(202, 202, 202);\n    font-family: arial;\n    font-size: 0.8em;\n}\n\n.maze-icon {\n    width: 40px;\n    height: auto;\n}\n\n.maze-icon:hover {\n    cursor:help\n}\n\n.support-section {\nmargin-left: 5%;\n}\n\n.heart-icon{\n    width: 20px;\n    height: auto;\n}\n\n.support-link {\n    color: #000; /* Adjust color as needed */\n    text-decoration: none;\n    margin-left:10px;\n    font-family: Verdana, Geneva, Tahoma, sans-serif;\n  }\n  \n  .support-link:hover {\n    text-decoration: underline;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
