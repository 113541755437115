import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function Out() {
  const navigate = useNavigate();
  const [userInput, setUserInput] = useState('');

  const handleInputChange = (event) => {
    const input = event.target.value;
    setUserInput(input);
    if (input.toUpperCase() === 'FANTASY') {
      navigate('/crawl'); // Navigate when the condition is met
    }
  };

  return (
    <div className='App'>
        <h1>It's been here the whole time</h1>
      <input
        type="text"
        value={userInput}
        onChange={handleInputChange}
        className="Center-Input"
        placeholder=""
      />
      
    </div>
  );
}

export default Out;
