import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Buy = () => {
    const welcomeMessage = `You made it to the Maze Terminal\nYou'll find the answer if you look for it\nGo ahead and help yourself`;
  const navigate = useNavigate();
  const formatOutput = (text) => {
    return text.split('\n').map((line, index) => (
      <span key={index}>
        {line}
        <br />
      </span>
    ));
  };
  const [input, setInput] = useState('');
  const [output, setOutput] = useState([formatOutput(welcomeMessage)]);
  const outputRef = useRef(null);
  const inputRef = useRef(null); // Ref for the input field

  const focusInput = () => {
    inputRef.current && inputRef.current.focus();
  };

  useEffect(() => {
    if (outputRef.current) {
      outputRef.current.scrollTop = outputRef.current.scrollHeight;
    }
  }, [output]);

  const handleInputChange = (event) => {
    setInput(event.target.value);
  };

  

  const fileCommands = {
    'secret': 'The secret of the maze is that there are no secret.',
    'maze map': 'The maze is complex and can\'t be mapped.',
    'escape route': 'Seems like this route is blocked.',
    'hidden passage': 'It’s locked. There must be a key somewhere.',
    'riddle': 'Riddles won\'t let you out',
    'key to freedom': 'This key is missing. Where could it be?',
    'buy': 'To get closer to the exit, freedom was the key',
    'archive': 'Old records of those who navigated the maze. Some never made it out.'
  };

  const fileList = [
    'secret',
    'maze map',
    'escape route',
    'hidden passage',
    'riddle',
    'key to freedom',
    'buy',
    'archive'
  ];

  const handleSubmit = (event) => {
    event.preventDefault();
    const inputTrimmed = input.trim().toLowerCase();
    const inputParts = inputTrimmed.split(' ');
    const command = inputParts[0];
    const argument = inputParts.slice(1).join(' ');
  
    let newOutput;
    if (input.trim().toLowerCase() === 'freedom') {
      navigate('/sell');
    } else {
      // Handle all command cases
      if (input.trim().toLowerCase() === 'ls') {
        newOutput = '> ' + input + "\n" + fileList.join('\n');
      } else if (command === 'cd' && fileList.includes(argument)) {
        newOutput = '> ' + input + "\n" + fileCommands[argument];  
      } else if (input.trim().toLowerCase() === 'help') {
        newOutput = '> ' + input + "\nWelcome to the maze...\nls - view files and folders\ncd [file name] - open file";
      } else {
        newOutput = '> ' + input + "\nUnknown command";
      }
  
      // Format and update the output
      setOutput([...output, ...formatOutput(newOutput)]);
    }
    setInput('');
  };  


  return (
    <div className="terminal" onClick={focusInput}>
      <div className="terminal-output" ref={outputRef}>
        {output.map((line, index) => (
          <div className="terminal-return" key={index}>{line}</div>
        ))}
      </div>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={input}
          onChange={handleInputChange}
          className="terminal-input"
          placeholder="Type here..."
          ref={inputRef} // Attach the ref to the input
          autoFocus
        />
      
      </form>
    </div>
  );
};

export default Buy;
