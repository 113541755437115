// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.maze {
    padding-top:100px;
    display: grid;
    grid-template-columns: repeat(10, 1fr); /* Creates 10 columns */
    grid-auto-rows: 1fr; /* Makes the row height the same as column width */
    gap: 0; /* No gap between cells */
    max-width: 500px; /* Adjust as needed for your layout */
    margin: auto; /* Center the maze */
  }
  
  
  .maze-cell {
    width: 50px;
    height: 50px;
    border: 1px solid black;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .character {
    background-color: blue; 
  }
  
  .exit {
    background-color: green; 
  }
  
  .controls {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  
  .controls button {
    margin: 0 5px;
  }

  .enemy {
    position: relative;
    width: 50px;
    height: 50px;
  }
  `, "",{"version":3,"sources":["webpack://./src/Crawl.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,aAAa;IACb,sCAAsC,EAAE,uBAAuB;IAC/D,mBAAmB,EAAE,kDAAkD;IACvE,MAAM,EAAE,yBAAyB;IACjC,gBAAgB,EAAE,qCAAqC;IACvD,YAAY,EAAE,oBAAoB;EACpC;;;EAGA;IACE,WAAW;IACX,YAAY;IACZ,uBAAuB;IACvB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;EACzB;;EAEA;IACE,sBAAsB;EACxB;;EAEA;IACE,uBAAuB;EACzB;;EAEA;IACE,aAAa;IACb,uBAAuB;IACvB,gBAAgB;EAClB;;EAEA;IACE,aAAa;EACf;;EAEA;IACE,kBAAkB;IAClB,WAAW;IACX,YAAY;EACd","sourcesContent":[".maze {\n    padding-top:100px;\n    display: grid;\n    grid-template-columns: repeat(10, 1fr); /* Creates 10 columns */\n    grid-auto-rows: 1fr; /* Makes the row height the same as column width */\n    gap: 0; /* No gap between cells */\n    max-width: 500px; /* Adjust as needed for your layout */\n    margin: auto; /* Center the maze */\n  }\n  \n  \n  .maze-cell {\n    width: 50px;\n    height: 50px;\n    border: 1px solid black;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n  }\n  \n  .character {\n    background-color: blue; \n  }\n  \n  .exit {\n    background-color: green; \n  }\n  \n  .controls {\n    display: flex;\n    justify-content: center;\n    margin-top: 10px;\n  }\n  \n  .controls button {\n    margin: 0 5px;\n  }\n\n  .enemy {\n    position: relative;\n    width: 50px;\n    height: 50px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
