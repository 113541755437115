import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './App.css';

function Walk() {
  const navigate = useNavigate();
  const [userInput, setUserInput] = useState('');
  const [typedText, setTypedText] = useState('');
  const [currentFrame, setCurrentFrame] = useState(0);
  const riddleText = "Inn the realm where time does dance,\nA fleeting, rushing, swift advance,\nThrough the forest, paths unseen,\nIn the chase, I am the queen..";

  useEffect(() => {
    let index = 0;
    const typeWriter = () => {
      if (index < riddleText.length) {
        setTypedText((prev) => prev + riddleText.charAt(index));
        index++;
        setTimeout(typeWriter, 100);
      }
    };
    const timeoutId = setTimeout(typeWriter, 100);
    return () => clearTimeout(timeoutId);
  }, [riddleText]);

  
  useEffect(() => {
    let intervalId;
  
    if (typedText.length < riddleText.length - 1) {
      // Start the mouth animation
      intervalId = setInterval(() => {
        setCurrentFrame((prevFrame) => (prevFrame + 1) % 2);
      }, 100); 
    } else {
      // Stop the animation by setting the frame to 0 (or whichever frame you want static)
      setCurrentFrame(0);
    }
  
    // Cleanup function to clear the interval
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [typedText, riddleText.length]);  

  const handleInputChange = (event) => {
    const input = event.target.value;
    setUserInput(input);
    if (input.toUpperCase() === 'WIND') {
      navigate('/run');
    }
  };

  // Decide which image to show based on whether the text is fully typed
  const currentImage = typedText.length < riddleText.length ?
    `/minotaur-body-${currentFrame + 1}.png` :
    '/minotaur-body-1.png';

  return (
    <div className='App'>
      <div className="content-container">
        <img src={currentImage} alt="Animating Minotaur" className="minotaur-image" />
        <div className="riddle-text">{typedText}</div>
      </div>
      <input
        type="text"
        value={userInput}
        onChange={handleInputChange}
        className="Center-Input"
        placeholder=""
      />
    </div>
  );
}

export default Walk;
