import React, { useEffect, useState } from 'react';
import './App.css';

function Freeornot() {
  const [animate, setAnimate] = useState(false);
  const [videoControls, setVideoControls] = useState(true);
  const [showOverlay, setShowOverlay] = useState(false);

  useEffect(() => {
    setAnimate(true);

    // Start a timer for 25 seconds
    const timer = setTimeout(() => {
      setShowOverlay(true);

      // After 3 seconds, navigate to '/'
      setTimeout(() => {
        window.location.href = '/';
      }, 6000);
    }, 25000);

    // Clear the timer when the component unmounts
    return () => clearTimeout(timer);
  }, []);

  const handleVideoPlay = () => {
    setVideoControls(false);
  };

  const leftDoorImage = `${process.env.PUBLIC_URL}/DoorLeft.png`;
  const rightDoorImage = `${process.env.PUBLIC_URL}/DoorRight.png`;
  const frameImage = `${process.env.PUBLIC_URL}/Frame.png`;
  const doorSound = `${process.env.PUBLIC_URL}/Door.mp4`;
  const videoEnd = `${process.env.PUBLIC_URL}/End Credits.mp4`;

  return (
    <div className='App'>
      <div className="doors-container">
        <audio autoPlay>
          <source src={doorSound} type="audio/mp4" />
        </audio>
        <img src={frameImage} alt="Door Frame" className="door-frame" />
        <img src={rightDoorImage} alt="Left Door" className={`door left-door ${animate ? 'open' : ''}`} />
        <img src={leftDoorImage} alt="Right Door" className={`door right-door ${animate ? 'open' : ''}`} />
        <video 
          src={videoEnd} 
          controls={videoControls}
          onPlay={handleVideoPlay}
          key={videoEnd} 
          className="video-player"
        />
      </div>
      {showOverlay && (
          <div className="fullscreen-overlay">
          </div>
        )}
    </div>
  );
}

export default Freeornot;
