import React, { useState } from 'react';
import './App.css';

function Give() {
  const [image, setImage] = useState('/Floppy1.png'); // Initial image set to Floppy1

  const handleMouseDown = () => {
    setImage('/Floppy2.png'); // Change to Floppy2 on mouse down
  };

  const handleMouseUp = () => {
    setImage('/Floppy1.png'); // Revert back to Floppy1 on mouse up
  };

  return (
    <div className='App'>
        <h1>By uncovering the truth you'll get direction</h1>
        <h1>It'll be up to you to build your own path</h1>
        <img 
          src={process.env.PUBLIC_URL + image}
          className="floppy"
          alt="Floppy Disk" 
          onMouseDown={handleMouseDown} 
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseUp} // Revert back to Floppy1 if mouse leaves the image while pressing
        />
    </div>
  );
}

export default Give;
