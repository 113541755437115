import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Merge = () => {
    const navigate = useNavigate();
    const [sequence, setSequence] = useState(Array(4).fill(''));

    const handleNumberClick = (number) => {
        // Find the first empty slot in the sequence
        const emptyIndex = sequence.findIndex((val) => val === '');
        if (emptyIndex !== -1) {
            const newSequence = [...sequence];
            newSequence[emptyIndex] = number;
            setSequence(newSequence);

            // Check if the sequence is correct
            if (newSequence.join('') === '1991') {
                navigate('/retain'); // Navigate to the next component
            } else if (newSequence.every(val => val !== '')) {
                // Check if all slots are filled and reset if the sequence is incorrect
                setTimeout(() => setSequence(Array(4).fill('')), 500); // Reset after a delay
            }
        }
    };

    return (
        <div className='App'>
            <p>In an epoch's embrace, the year it began its race</p>
            <p>Through gates of time, its pace was set to chase</p>
            <p>Not with a sprint, but with an open source's grace</p>
            <p>A penguin's march, in the tech space's vast maze</p>
            <div className="puzzle-container">
                {[9, 1].map((number, index) => (
                    <div key={index} className="puzzle-piece" onClick={() => handleNumberClick(number)}>
                        {number}
                    </div>
                ))}
                <div className="drop-zones">
                    {sequence.map((val, index) => (
                        <div key={index} className="drop-zone">{val}</div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Merge;
