import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function Iron() {
  const navigate = useNavigate();
  const [userInput, setUserInput] = useState('');

  const handleInputChange = (event) => {
    const input = event.target.value;
    setUserInput(input);
    if (input.toUpperCase() === '42') {
      navigate('/iron'); // Navigate to Air component when 'ESCAPE' is entered
    }
  };
  const encodedMessage = 'aHR0cHM6Ly93d3cuZ29vZ2xlLmNvbS9tYXBzL0A0OC44NzY1NjMxLDIuMzQxMTgwNSwzYSwxNXksMzA4LjM1aCw5My41NnQvZGF0YT0hM202ITFlMSEzbTQhMXNGVXJmNXZzZUlpclUxOWVCX0dQVk9BITJlMCE3aTE2Mzg0IThpODE5Mj9lbnRyeT10dHU=';

  return (
    <div className="App">
    <div className="App-header">
    <h1>The number you'll get will help you get the number to get out</h1>
    <p>2 * 2 * 2 * 2 * 2 * 2</p>
      <p>{encodedMessage}</p>
        <input
          type="text"
          value={userInput}
          onChange={handleInputChange}
          placeholder=""
          className='Center-Input'
        />
    </div>
    </div>
  );
}

export default Iron;



