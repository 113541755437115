import React, {  } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import Header from './Header';
import Cotton from './1Cotton';
import Iron from './2Iron';
import Air from './3Air';
import Gold from './4Gold';
import Silver from './5Silver';
import Debt from './6Debt';
import Buy from './7Buy';
import Sell from './8Sell';
import Rent from './9Rent';
import Merge from './10Merge';
import Retain from './11Retain';
import Give from './12Give';
import Juliet from './13Juliet';
import Out from './14Out';
import Crawl from './15Crawl';
import Walk from './16Walk';
import Run from './17Run';
import Freeornot from './18Freeornot';
import './App.css';

function App() {
  const navigate = useNavigate(); // Hook to control navigation

  const redirectToCotton = () => {
    navigate('/cotton'); // This will change the URL to '/cotton'
  };

  function isMobileDevice() {
    return (
      (navigator.userAgent.match(/Android/i)) ||
      (navigator.userAgent.match(/webOS/i)) ||
      (navigator.userAgent.match(/iPhone/i)) ||
      (navigator.userAgent.match(/iPad/i)) ||
      (navigator.userAgent.match(/iPod/i)) ||
      (navigator.userAgent.match(/BlackBerry/i)) ||
      (navigator.userAgent.match(/Windows Phone/i))
    );
  }

  if (isMobileDevice()) {
    return (
      
      <div className="App-header">
      <div className="mobile-access-denied">
        <img src="/broken-phone.png" className="small-image" alt="broken-phone-pixel art"></img>
        <p>The Lost Maze can't be completed on a mobile device.</p>
        <p>Join us on your favorite desktop device.</p>
      </div>
      </div>
      
    );
  }  

  return (
    <div className="App">
      <div className="App-header">
      <img src="/Maze.png" alt="Display" className="mazehero"/>
        <h1>Welcome to the Lost Maze</h1>
        <p>This is a lonely game</p>
        <p>The prize can't be shared</p>
        <p>It'll take time and determination to complete this quest</p>
        <p>Think out of the box, explore, dig</p>
        <p>And leave the maze...</p>
        <p>Good luck</p>
      
        <button onClick={redirectToCotton} className="Navigate-Button">
          Enter the Maze
        </button>
      </div>
    </div>
  );
}

function AppWrapper() {
  return (
    <Router>
      <Header/>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/cotton" element={<Cotton />} />
        <Route path="/iron" element={<Iron />} />
        <Route path="/air" element={<Air />} />
        <Route path="/gold" element={<Gold />} />
        <Route path="/silver" element={<Silver />} />
        <Route path="/debt" element={<Debt />} />
        <Route path="/buy" element={<Buy />} />
        <Route path="/sell" element={<Sell />} />
        <Route path="/rent" element={<Rent />} />
        <Route path="/merge" element={<Merge />} />
        <Route path="/retain" element={<Retain />} />
        <Route path="/give" element={<Give />} />
        <Route path="/juliet" element={<Juliet />} />
        <Route path="/out" element={<Out />} />
        <Route path="/crawl" element={<Crawl />} />
        <Route path="/walk" element={<Walk />} />
        <Route path="/run" element={<Run />} />
        <Route path="/freeornot" element={<Freeornot />} />
      </Routes>
    </Router>
  );
}

export default AppWrapper;
